import { getAirlines } from 'api/flights'
import { Airline } from 'components/Flights/types'
import debounce from 'debounce-promise'
import { useEffect, useMemo, useRef, useState } from 'react'

interface Options {
  maxResults?: number
  debounceDelay?: number
  characterThreshold?: number
}

const useAirlineTypeahead = (searchPhrase: string, options?: Options) => {
  const { maxResults = 15, debounceDelay = 500, characterThreshold = 1 } = options

  const isMountedRef = useRef(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [results, setResults] = useState<Array<Airline>>([])

  const shouldSearch = searchPhrase.length > characterThreshold

  const getDebouncedAirlinePredictions = useMemo(() => debounce((value: string) => {
    getAirlines(value).then(airlines => {
      if (isMountedRef) {
        setResults(airlines.slice(0, maxResults))
        setIsLoading(false)
      }
    }).catch(() => {
      if (isMountedRef.current) {
        setIsError(true)
        setIsLoading(false)
      }
    })
  }, debounceDelay), [debounceDelay, maxResults])

  useEffect(() => {
    if (shouldSearch) {
      setIsLoading(true)
      getDebouncedAirlinePredictions(searchPhrase)
    } else {
      setResults([])
    }

    return () => {
      isMountedRef.current = false
    }
  }, [searchPhrase, getDebouncedAirlinePredictions, shouldSearch])

  const hasResults = results.length > 0 && shouldSearch
  const airlines = shouldSearch ? results : []

  return { results: airlines, isLoading, isError, hasResults }
}

export default useAirlineTypeahead
