import { getAirportsSearch } from 'api/flights'
import debounce from 'debounce-promise'
import { useEffect, useMemo, useRef, useState } from 'react'

interface Options {
  maxResults?: number
  debounceDelay?: number
  characterThreshold?: number
  noParentAirports?: boolean
}

const useAirportTypeahead = (searchPhrase: string, options: Options = {}) => {
  const { maxResults = 15, debounceDelay = 500, characterThreshold = 1, noParentAirports = false } = options

  const isMountedRef = useRef(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [results, setResults] = useState<Array<App.AirportLocation>>([])

  const shouldSearch = searchPhrase.length > characterThreshold

  const getDebouncedAirportPredictions = useMemo(() => debounce((value: string) => {
    getAirportsSearch(value).then(airports => {
      if (isMountedRef) {
        setResults(airports.slice(0, maxResults).filter(airport =>
          noParentAirports ? !airport.isMultiAirport : true,
        ))
        setIsLoading(false)
      }
    }).catch(() => {
      if (isMountedRef.current) {
        setIsError(true)
        setIsLoading(false)
      }
    })
  }, debounceDelay), [debounceDelay, noParentAirports, maxResults])

  useEffect(() => {
    if (shouldSearch) {
      setIsLoading(true)
      getDebouncedAirportPredictions(searchPhrase)
    } else {
      setResults([])
    }

    return () => {
      isMountedRef.current = false
    }
  }, [searchPhrase, getDebouncedAirportPredictions, shouldSearch])

  const hasResults = results.length > 0 && shouldSearch
  const airports = shouldSearch ? results : []

  return { results: airports, isLoading, isError, hasResults }
}

export default useAirportTypeahead
