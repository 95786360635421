import { rem } from 'polished'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import {
  priceFieldProps,
  priceRegisterConfig,
  airportConfig,
  urlRegisterConfig,
  flightConfirmationCodeConfig,
} from './Fields/config'
import ConfirmedBooking from './Fields/ConfirmedBooking'
import RHFDatePickerInput from './Fields/RHFDatePickerInput'
import Input from './Fields/RHFInput'
import TextArea from './Fields/RHFTextArea'
import ShowMore from './Fields/ShowMore'
import TimeInput from './Fields/TimeInput'
import { useDefaultDatePickerMonthYear } from './Hooks/useDefaultDates'
import { CreateFlightItemViewModel } from '../Create/models'
import { PatchFlightItemViewModel } from '../Edit/models'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import AirlineAutocomplete from 'tripPlanner/components/AutocompleteInputs/AirlineAutocomplete'
import AirportAutocomplete from 'tripPlanner/components/AutocompleteInputs/AirportAutocomplete'

const DividerContainer = styled.div`
  margin-top: ${rem(40)};
  height: ${rem(24)};
`

const StyledVerticalSpacer = styled(VerticalSpacer)`
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
`

const PriceInput = styled(Input)`
  width: ${rem(100)};
`

interface Props {
  tripId: string
}

function FlightFields({ tripId }: Props) {
  const methods = useFormContext<
    CreateFlightItemViewModel | PatchFlightItemViewModel
  >()
  const { control, setValue, watch, register, getValues } = methods

  const { defaultMonth, defaultYear } = useDefaultDatePickerMonthYear({
    tripId,
  })

  const watchBooked = watch('isBooked')

  const handleAirportOptionChange = useCallback(
    (place: 'origin' | 'destination', airport: App.AirportLocation) => {
      const placeType = place === 'origin' ? 'startPlace' : 'endPlace'

      if (place === 'origin') {
        setValue('originAirportCode', airport.airportCode)
      } else {
        setValue('destinationAirportCode', airport.airportCode)
      }

      if (airport) {
        if (airport.lt && airport.lg) {
          setValue(`${placeType}.type`, 'GEO')
          setValue(`${placeType}.point.lat`, airport.lt)
          setValue(`${placeType}.point.lng`, airport.lg)
        } else {
          setValue(`${placeType}.type`, 'CUSTOM')
        }

        setValue(`${placeType}.name`, airport.airportName)
        setValue(
          `${placeType}.address`,
          `${airport.cityAirportName}, ${airport.country}`,
        )
        setValue(
          `${placeType}.region`,
          `${airport.cityAirportName}, ${airport.country}`,
        )
      }
    },
    [setValue],
  )

  const handleDepartureChange = useCallback(
    (selectedOption: App.AirportLocation) => {
      handleAirportOptionChange('origin', selectedOption)
    },
    [handleAirportOptionChange],
  )

  const handleArrivalChange = useCallback(
    (selectedOption: App.AirportLocation) => {
      handleAirportOptionChange('destination', selectedOption)
    },
    [handleAirportOptionChange],
  )

  return (
    <StyledVerticalSpacer gap={16}>
      <VerticalSpacer gap={32}>
        <AirlineAutocomplete
          {...register('airlineName')}
          label="Airline"
          placeholder="Search by airline name or code"
          initAirlineName={getValues('airlineName') ?? undefined}
        />

        <Input
          {...register('flightNumber')}
          label="Flight number"
          placeholder="Flight number"
        />

        <Heading variant="heading4">Departure details</Heading>
      </VerticalSpacer>

      <VerticalSpacer gap={32}>
        <AirportAutocomplete
          {...register('originAirportCode')}
          label="Airport"
          placeholder="Search by airport name or code"
          required
          onAirportSelect={handleDepartureChange}
          noParentAirports
        />

        <Group direction="horizontal" horizontalAlign="stretch" gap={16}>
          <RHFDatePickerInput
            name="startDate"
            label="Date"
            defaultMonth={defaultMonth}
            defaultYear={defaultYear}
          />
          <TimeInput
            {...register('startTime')}
            label="Time"
            placeholder="Select time"
          />
        </Group>
      </VerticalSpacer>

      <DividerContainer>
        <Divider kind="primary" />
      </DividerContainer>

      <Heading variant="heading4">Arrival details</Heading>

      <VerticalSpacer gap={32}>
        <AirportAutocomplete
          {...register('destinationAirportCode', { ...airportConfig })}
          label="Airport"
          placeholder="Search by airport name or code"
          required
          onAirportSelect={handleArrivalChange}
          noParentAirports
        />

        <Group direction="horizontal" horizontalAlign="stretch" gap={16}>
          <RHFDatePickerInput
            name="endDate"
            label="Date"
            defaultMonth={defaultMonth}
            defaultYear={defaultYear}
          />
          <TimeInput
            {...register('endTime')}
            label="Time"
            placeholder="Select time"
          />
        </Group>
      </VerticalSpacer>

      {/* <CheckboxField
        defaultChecked={getValues('isEndDateSame')}
        label="Arrival day is the same as departure"
        onClick={(e) => setValue('isEndDateSame', e.currentTarget.checked)}
      /> */}

      <Controller
        name="isBooked"
        control={control}
        render={({ field }) => (
          <ConfirmedBooking
            toggled={!!field.value}
            onToggle={() => field.onChange(!field.value)}
          />
        )}
      />

      <ShowMore>
        <Group
          direction="horizontal"
          horizontalAlign="stretch"
          verticalAlign="center"
          gap={16}
        >
          {/* <Controller
          name="fareClass"
          control={control}
          render={({ field }) => {
            return (
              <Select {...field} label="Cabin class">
                {fareCabins.map((opt) => (
                  <option key={opt.value} value={opt.value} label={opt.label}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            )
          }}
        /> */}
          {watchBooked && (
            <Input
              {...register('confirmationCode', {
                ...flightConfirmationCodeConfig,
              })}
              label="Booking reference"
              placeholder="Booking reference"
            />
          )}
        </Group>
        <PriceInput
          {...register('price', { ...priceRegisterConfig })}
          label="Price"
          placeholder="0.00"
          {...priceFieldProps}
        />

        <Input
          {...register('url', { ...urlRegisterConfig })}
          label="Website"
          placeholder="https://"
        />

        <TextArea
          {...register('notes')}
          placeholder="Add note here"
          label="Note"
          size="tall"
          resize="vertical"
        />
      </ShowMore>
    </StyledVerticalSpacer>
  )
}

export default FlightFields
