import { rem } from 'polished'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { AccommodationDateTimeFields } from './Fields/AccommodationDateTimeFields'
import AddressDisplay from './Fields/AddressDisplay'
import {
  priceFieldProps,
  priceRegisterConfig,
  required,
  urlRegisterConfig,
} from './Fields/config'
import ConfirmedBooking from './Fields/ConfirmedBooking'
import Input from './Fields/RHFInput'
import TextArea from './Fields/RHFTextArea'
import ShowMore from './Fields/ShowMore'
import useEnsureGeoPlace from './Hooks/useEnsureGeoPlace'
import { CreateAccommodationItemViewModel } from '../Create/models'
import { PatchAccommodationItemViewModel } from '../Edit/models'

import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import Group from 'components/utils/Group'
import TripAddressAutocomplete, {
  TripAddressAutocompleteOption,
} from 'tripPlanner/components/AutocompleteInputs/TripAddressAutocomplete'
import { useComponentRestrictions } from 'tripPlanner/hooks/Google/useComponentRestrictions'
import {
  AutocompletePlaceParam,
  ESTABLISHMENT_RESPONSE_DETAILS,
} from 'tripPlanner/types/google'
import { convertGoogleAddressComponentsToRegion } from 'tripPlanner/utils'

const PriceInput = styled(Input)`
  width: ${rem(100)};
`

const AUTOCOMPLETE_TYPES: AutocompletePlaceParam = []

const GEO_DEP_KEYS: Array<string> = ['place.name']

interface Props {
  tripId: string
}

function AccommodationFields({ tripId }: Props) {
  const methods = useFormContext<
    CreateAccommodationItemViewModel | PatchAccommodationItemViewModel
  >()
  const { control, setValue, watch, register } = methods

  useEnsureGeoPlace(methods, 'place', GEO_DEP_KEYS)
  const componentRestrictions = useComponentRestrictions(tripId)

  const place = watch('place')

  const handleAddressChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      setValue(
        'place.name',
        selectedOption.details?.name || selectedOption.label,
      )

      setValue('place.type', selectedOption.prediction ? 'GEO' : 'CUSTOM')

      if (selectedOption.prediction) {
        const { place_id } = selectedOption.prediction
        setValue('place.id', place_id)
      }

      if (selectedOption.details) {
        const {
          geometry,
          formatted_address,
          international_phone_number,
          website,
          address_components,
        } = selectedOption.details

        if (address_components) {
          const region =
            convertGoogleAddressComponentsToRegion(address_components)
          if (region) {
            setValue('place.region', region)
          }
        }

        if (website) {
          setValue('url', website)
        }
        setValue('phone', international_phone_number)
        formatted_address && setValue('place.address', formatted_address)

        if (geometry?.location) {
          setValue('place.point.lat', geometry.location.lat)
          setValue('place.point.lng', geometry.location.lng)
        }
      }
    },
    [setValue],
  )

  const handlePlaceNameChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      setValue(
        'place.name',
        selectedOption.details?.name || selectedOption.label,
      )

      handleAddressChange(selectedOption)
    },
    [handleAddressChange, setValue],
  )

  return (
    <Group direction="vertical" gap={16}>
      <TripAddressAutocomplete
        {...register('place.name', { required })}
        required
        label="Accommodation"
        placeholder="Search by hotel name or location"
        initPlaceId={place?.type === 'GEO' ? place.id : undefined}
        autocompleteTypes={AUTOCOMPLETE_TYPES}
        details={ESTABLISHMENT_RESPONSE_DETAILS}
        onPlaceSelect={handlePlaceNameChange}
        startIcon={<LineSearchIcon />}
        autocompletionReqParam={{
          componentRestrictions,
        }}
      />
      {place?.address && (
        <AddressDisplay title="Address" value={place.address} />
      )}
      <AccommodationDateTimeFields />

      <Controller
        name="isBooked"
        control={control}
        render={({ field }) => (
          <ConfirmedBooking
            toggled={!!field.value}
            onToggle={() => field.onChange(!field.value)}
          />
        )}
      />

      <ShowMore>
        <PriceInput
          {...register('price', { ...priceRegisterConfig })}
          label="Price"
          placeholder="0.00"
          {...priceFieldProps}
        />

        <Input
          {...register('url', { ...urlRegisterConfig })}
          label="Website"
          placeholder="https://"
        />

        <TextArea
          {...register('notes')}
          size="tall"
          placeholder="Add note here"
          label="Note"
          resize="vertical"
        />
      </ShowMore>
    </Group>
  )
}

export default AccommodationFields
