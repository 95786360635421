import { rem } from 'polished'
import React, { useCallback } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import AddressDisplay from './Fields/AddressDisplay'
import {
  priceFieldProps,
  priceRegisterConfig,
  required,
  urlRegisterConfig,
} from './Fields/config'
import ConfirmedBooking from './Fields/ConfirmedBooking'
import RHFDatePickerInput from './Fields/RHFDatePickerInput'
import Input from './Fields/RHFInput'
import TextArea from './Fields/RHFTextArea'
import ShowMore from './Fields/ShowMore'
import TimeInput from './Fields/TimeInput'
import { useDefaultDatePickerMonthYear } from './Hooks/useDefaultDates'
import useEndPlaceIsSame from './Hooks/useEndPlaceIsSame'
import useEnsureGeoPlace from './Hooks/useEnsureGeoPlace'
import { CreateTourItemViewModel } from '../Create/models'
import { PatchTourItemViewModel } from '../Edit/models'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import TripAddressAutocomplete, {
  TripAddressAutocompleteOption,
} from 'tripPlanner/components/AutocompleteInputs/TripAddressAutocomplete'
import {
  ADDRESS_RESPONSE_DETAILS,
  AutocompletePlaceParam,
} from 'tripPlanner/types/google'
import { convertGoogleAddressComponentsToRegion } from 'tripPlanner/utils'

const PriceInput = styled(Input)`
  width: ${rem(100)};
`

const EndPlaceContainer = styled.div`
  padding-bottom: ${rem(8)};
`

const DividerContainer = styled.div`
  height: ${rem(24)};
`

const StyledVerticalSpacer = styled(VerticalSpacer)`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`

const AUTOCOMPLETE_TYPES: AutocompletePlaceParam = []

const START_GEO_DEP_KEYS: Array<string> = ['startPlace.name']
const END_GEO_DEP_KEYS: Array<string> = ['endPlace.name']

interface Props {
  tripId: string
}

function TourFields({ tripId }: Props) {
  const methods = useFormContext<
    CreateTourItemViewModel | PatchTourItemViewModel
  >()
  const { watch, control, setValue, register, clearErrors } = methods

  const { defaultMonth, defaultYear } = useDefaultDatePickerMonthYear({
    tripId,
  })

  useEnsureGeoPlace(methods, 'startPlace', START_GEO_DEP_KEYS)
  useEnsureGeoPlace(methods, 'endPlace', END_GEO_DEP_KEYS)

  const startPlace = watch('startPlace')
  const endPlace = watch('endPlace')

  useEndPlaceIsSame(methods)

  const handleAddressChange = useCallback(
    (
      placeName: 'startPlace' | 'endPlace',
      selectedOption: TripAddressAutocompleteOption,
    ) => {
      setValue(
        `${placeName}.name`,
        selectedOption.details?.name || selectedOption.label,
      )

      setValue(
        `${placeName}.type`,
        selectedOption.prediction ? 'GEO' : 'CUSTOM',
      )

      if (selectedOption.prediction) {
        const { place_id } = selectedOption.prediction
        setValue(`${placeName}.id`, place_id)
      }

      if (selectedOption.details) {
        const { geometry, formatted_address, website, address_components } =
          selectedOption.details

        if (address_components) {
          const region =
            convertGoogleAddressComponentsToRegion(address_components)
          if (region) {
            setValue(`${placeName}.region`, region)
          }
        }

        if (website) {
          setValue('url', website)
        }
        formatted_address && setValue(`${placeName}.address`, formatted_address)

        if (geometry?.location) {
          setValue(`${placeName}.point.lat`, geometry.location.lat)
          setValue(`${placeName}.point.lng`, geometry.location.lng)
        }
      }
    },
    [setValue],
  )

  const handleStartAddressChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      handleAddressChange('startPlace', selectedOption)
    },
    [handleAddressChange],
  )

  const handleEndAddressChange = useCallback(
    (selectedOption: TripAddressAutocompleteOption) => {
      handleAddressChange('endPlace', selectedOption)
    },
    [handleAddressChange],
  )

  const {
    fieldState: { error: endPlaceError },
  } = useController({ name: 'endPlace' })

  const handleSetStartPlaceToEndPlace = useCallback(() => {
    setValue('endPlace', startPlace)

    // React hook form not recognizing valid field after this update
    if (endPlaceError) {
      clearErrors('endPlace')
    }
  }, [setValue, startPlace, clearErrors, endPlaceError])

  return (
    <StyledVerticalSpacer gap={16}>
      <Input
        {...register('name', { required })}
        required
        label="Tour name"
        placeholder="Enter a tour name"
      />

      <Heading variant="heading4">Pick up details</Heading>

      <TripAddressAutocomplete
        {...register('startPlace.name', { required })}
        required
        label="Pick up location"
        placeholder="Search for location"
        initPlaceId={startPlace?.type === 'GEO' ? startPlace.id : undefined}
        autocompleteTypes={AUTOCOMPLETE_TYPES}
        details={ADDRESS_RESPONSE_DETAILS}
        onPlaceSelect={handleStartAddressChange}
        startIcon={<LineMapMarkerIcon />}
      />
      {startPlace?.address && (
        <AddressDisplay title="Address" value={startPlace.address} />
      )}

      <Group direction="horizontal" horizontalAlign="stretch" gap={16}>
        <RHFDatePickerInput
          name="startDate"
          label="Pick up date"
          defaultMonth={defaultMonth}
          defaultYear={defaultYear}
        />
        <TimeInput
          {...register('startTime')}
          label="Time"
          placeholder="Select time"
        />
      </Group>

      <DividerContainer>
        <Divider kind="primary" />
      </DividerContainer>

      <Heading variant="heading4">Drop off details</Heading>

      <EndPlaceContainer>
        <TripAddressAutocomplete
          {...register('endPlace.name', { required })}
          required
          label="Drop-off location"
          placeholder="Search for location"
          initPlaceId={endPlace?.type === 'GEO' ? endPlace.id : undefined}
          autocompleteTypes={AUTOCOMPLETE_TYPES}
          details={ADDRESS_RESPONSE_DETAILS}
          onPlaceSelect={handleEndAddressChange}
          startIcon={<LineMapMarkerIcon />}
          helpText={
            <BodyText variant="medium">
              Add same address as{' '}
              <TextLink
                onClick={handleSetStartPlaceToEndPlace}
                underline={false}
              >
                pick up location
              </TextLink>
            </BodyText>
          }
        />
        {endPlaceError && (
          <BodyText variant="medium">
            Add same address as{' '}
            <TextLink onClick={handleSetStartPlaceToEndPlace} underline={false}>
              pick up location
            </TextLink>
          </BodyText>
        )}
      </EndPlaceContainer>

      {endPlace?.address && (
        <AddressDisplay title="Address" value={endPlace.address} />
      )}

      <Group direction="horizontal" horizontalAlign="stretch" gap={16}>
        <RHFDatePickerInput
          name="endDate"
          label="Drop-off date"
          defaultMonth={defaultMonth}
          defaultYear={defaultYear}
        />
        <TimeInput
          {...register('endTime')}
          label="Time"
          placeholder="Select time"
        />
      </Group>

      <DividerContainer>
        <Divider kind="primary" />
      </DividerContainer>

      <Controller
        name="isBooked"
        control={control}
        render={({ field }) => (
          <ConfirmedBooking
            toggled={!!field.value}
            onToggle={() => field.onChange(!field.value)}
          />
        )}
      />

      <ShowMore>
        <Input
          {...register('operatorName')}
          label="Operator"
          placeholder="Tour operator"
        />

        <PriceInput
          {...register('price', { ...priceRegisterConfig })}
          label="Price"
          placeholder="0.00"
          {...priceFieldProps}
        />

        <Input
          {...register('url', { ...urlRegisterConfig })}
          label="Website"
          placeholder="https://"
        />

        <TextArea
          {...register('notes')}
          placeholder="Add note here"
          label="Note"
          size="tall"
          resize="vertical"
        />
      </ShowMore>
    </StyledVerticalSpacer>
  )
}

export default TourFields
