import React from 'react'

import MobileSelectDates from './MobileSelectDates'

import useModalElementContext from 'hooks/Modal/useModalElementContext'
import noop from 'lib/function/noop'

interface Props extends React.ComponentProps<typeof MobileSelectDates> {}

function MobileSelectDatesSelfClose(props: Props) {
  const {
    onChange: onChangeProp,
    onRemove: onRemoveProp = noop,
    onCancel: onCancelProp = noop,
    ...rest
  } = props

  const modalElement = useModalElementContext()

  const onChange = (startDate: moment.Moment, endDate?: moment.Moment) => {
    onChangeProp(startDate, endDate)
    modalElement.resolve()
  }

  const onRemove = () => {
    onRemoveProp()
    modalElement.resolve()
  }

  const onCancel = () => {
    onCancelProp()
    modalElement.resolve()
  }

  return (
    <MobileSelectDates
      {...rest}
      onChange={onChange}
      onRemove={onRemove}
      onCancel={onCancel}
    />
  )
}

export default MobileSelectDatesSelfClose
