import { rem } from 'polished'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { required } from './Fields/config'
import RHFDatePickerInput from './Fields/RHFDatePickerInput'
import Input from './Fields/RHFInput'
import TextArea from './Fields/RHFTextArea'
import { useDefaultDatePickerMonthYear } from './Hooks/useDefaultDates'
import { CreateNoteItemViewModel } from '../Create/models'
import { PatchNoteItemViewModel } from '../Edit/models'

import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import Tooltip from 'components/Luxkit/Tooltip'
import Group from 'components/utils/Group'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLEStaff } from 'selectors/accountSelectors'
import CheckboxField from 'tripPlanner/components/TripModal/TripItems/Common/Fields/CheckboxField'

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(8)};
  align-items: center;
  margin-bottom: ${rem(24)};
`

const StyledCheckboxField = styled(CheckboxField)`
  margin-bottom: 0;
`

interface Props {
  tripId: string
}

function NoteFields({ tripId }: Props) {
  const { register, watch } = useFormContext<
    CreateNoteItemViewModel | PatchNoteItemViewModel
  >()

  const isLEStaffMember = useAppSelector(isLEStaff)

  const { defaultMonth, defaultYear } = useDefaultDatePickerMonthYear({
    tripId,
  })

  const isSectionHeaderSelected = !!watch('isSectionHeader')

  return (
    <>
      {isLEStaffMember && (
        <SectionHeader>
          <StyledCheckboxField
            {...register('isSectionHeader')}
            label="Section Header"
          />
          <Tooltip description="This is intended for use in Curated Trips. If this box is ticked, this note will appear as a section header above all items that start on the same day.">
            <LineInfoCircleIcon size="XS" />
          </Tooltip>
        </SectionHeader>
      )}
      <Input
        {...register('title', {
          required: isSectionHeaderSelected ? required : undefined,
        })}
        required={isSectionHeaderSelected}
        label="Title"
        placeholder="Add Title"
      />

      <TextArea
        {...register('notes', { required })}
        required
        label="Note"
        placeholder="Add note here"
        resize="vertical"
        rows={4}
      />
      <Group direction="vertical" horizontalAlign="stretch" gap={16}>
        <RHFDatePickerInput
          name="startDate"
          label="Date"
          required={isSectionHeaderSelected}
          defaultMonth={defaultMonth}
          defaultYear={defaultYear}
          placeholder="Select date"
        />
      </Group>
    </>
  )
}

export default NoteFields
