import React, {
  ChangeEventHandler,
  ComponentProps,
  FocusEventHandler,
  useCallback,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import BaseAutocompleteInput, {
  BaseAutocompleteProps,
} from './BaseAutocompleteInput'
import NoResultsCopy from '../Search/NoResultsCopy'
import Input from '../TripModal/TripItems/Common/Fields/RHFInput'

import { Airline } from 'components/Flights/types'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import useAirlineTypeahead from 'hooks/GlobalSearch/useAirlineTypeahead'

interface Props extends ComponentProps<typeof Input> {
  initAirlineName?: string
  value?: string
  label?: string
  name: string
  onAirlineSelect?: (option: Airline) => void
  placeholder?: string
}

const AirlineLogo = styled.img`
  height: 100%;
`

const CHARACTER_THRESHOLD = 1
const DEBOUNCE_DELAY = 300
const MAX_RESULTS = 5
const NO_RESULTS_MESSAGE = 'Try searching for a different airline.'

const presentationSelector: BaseAutocompleteProps<Airline>['presentationSelector'] =
  (option) => ({
    title: option.airlineName,
    subtitle: option.airlineCode,
    endIcon: <AirlineLogo src={option.airlineLogo} />,
  })

const AirlineAutocomplete = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      onChange,
      onFocus,
      onAirlineSelect,
      initAirlineName,
      placeholder,
      label,
    },
    ref,
  ) => {
    const [searchPhrase, setSearchPhrase] = useState(initAirlineName || '')

    const { setValue } = useFormContext()

    const {
      hasResults,
      isLoading: predictionsLoading,
      results,
    } = useAirlineTypeahead(searchPhrase, {
      debounceDelay: DEBOUNCE_DELAY,
      characterThreshold: CHARACTER_THRESHOLD,
      maxResults: MAX_RESULTS,
    })

    const handleSelect = useCallback(
      (airline: Airline) => {
        setValue(name, airline.airlineName, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
        onAirlineSelect?.(airline)
      },
      [name, setValue, onAirlineSelect],
    )

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSearchPhrase('')
        onFocus?.(e)
      },
      [onFocus],
    )

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setSearchPhrase(e.currentTarget.value)
        onChange?.(e)
      },
      [onChange],
    )

    return (
      <BaseAutocompleteInput
        canRenderDropdown={searchPhrase.length > CHARACTER_THRESHOLD}
        canRenderNoResult={!hasResults && !predictionsLoading}
        name={name}
        label={label}
        placeholder={placeholder}
        noResultComponent={
          <NoResultsCopy
            message={NO_RESULTS_MESSAGE}
            searchPhrase={searchPhrase}
          />
        }
        startIcon={<LineSearchIcon />}
        onChange={handleChange}
        onFocus={handleFocus}
        onOptionSelect={handleSelect}
        options={results}
        presentationSelector={presentationSelector}
        ref={ref}
        isLoading={predictionsLoading}
      />
    )
  },
)

export default AirlineAutocomplete
