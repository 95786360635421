import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import LineArrowRightIcon from 'components/Luxkit/Icons/line/LineArrowRightIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import {
  SHORT_DAY_NAME_DAY_MONTH,
  SHORT_DAY_NAME_DAY_MONTH_YEAR,
} from 'constants/dateFormats'
import { pluralizeToString } from 'lib/string/pluralize'
import { useIsDesktopScreen } from 'hooks/useScreenSize'
import DateRangePicker from 'tripPlanner/Tripkit/Common/DateRangePicker'

function getRangeDescription(
  startDate: moment.Moment,
  endDate: moment.Moment,
  rangeType: 'days' | 'nights',
) {
  if (rangeType === 'days') {
    return pluralizeToString('day', endDate.diff(startDate, 'days') + 1)
  } else {
    return pluralizeToString('night', endDate.diff(startDate, 'days'))
  }
}

interface Props {
  triggerRef: React.RefObject<HTMLElement>
  isOpen: boolean
  startDate?: moment.Moment
  endDate?: moment.Moment
  onDatesChange: (dates: {
    startDate: moment.Moment
    endDate?: moment.Moment | null
  }) => void
  onClear?: () => void
  onClose?: () => void
  startLabel?: string
  endLabel?: string
  rangeType: 'days' | 'nights'
  initDate?: moment.Moment
  portaled?: boolean
  placement?: 'top' | 'bottom'
}

function DateRangeDropdown({
  triggerRef,
  isOpen,
  startDate: startDateProp,
  endDate: endDateProp,
  onDatesChange,
  onClear,
  onClose,
  startLabel,
  endLabel,
  rangeType,
  initDate,
  portaled,
  placement = 'bottom',
}: Props) {
  const [dates, setDates] = useState<{ startDate?: moment.Moment; endDate?: moment.Moment }>({
    startDate: startDateProp,
    endDate: endDateProp,
  })
  const { startDate, endDate } = dates

  const hasDates = !!(startDate && endDate)

  useEffect(() => {
    setDates({ startDate: startDateProp, endDate: endDateProp })
  }, [startDateProp, endDateProp])

  const apply = useCallback(() => {
    if (dates.startDate) {
      onDatesChange(dates as { startDate: moment.Moment; endDate?: moment.Moment })
    }
  }, [dates, onDatesChange])

  const isDesktop = useIsDesktopScreen()

  return (
    <DropdownSheet
      triggerRef={triggerRef}
      anchorRef={triggerRef}
      size="L"
      open={isOpen}
      placement={placement}
      onClose={onClose}
      crossAxisShift
      portaled={portaled}
    >
      <DateRangePicker
        onDatesChange={setDates}
        startDate={startDate}
        endDate={endDate}
        startLabel={startLabel}
        endLabel={endLabel}
        initDate={initDate}
        type={isDesktop ? 'dual' : 'single'}
        allowSameDay={false}
        showTripDates
      />
      {!!onClear && (
        <Group direction="horizontal" horizontalAlign="end" gap={16}>
          {hasDates && (
            <Group direction="horizontal" gap={8} verticalAlign="center">
              <Group direction="horizontal" gap={4} verticalAlign="center">
                <BodyText variant="medium" weight="bold">
                  {startDate.format(SHORT_DAY_NAME_DAY_MONTH)}
                </BodyText>

                <LineArrowRightIcon size="S" />

                <BodyText variant="medium" weight="bold">
                  {endDate.format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}
                </BodyText>
              </Group>

              <BodyText variant="medium">
                ({getRangeDescription(startDate, endDate, rangeType)})
              </BodyText>
            </Group>
          )}
          <TextButton kind="tertiary" onClick={onClear} outdent="right">
            Clear dates
          </TextButton>
          <TextButton kind="primary" onClick={apply} disabled={!startDate}>
            Apply
          </TextButton>
        </Group>
      )}
    </DropdownSheet>
  )
}

export default DateRangeDropdown
