import { rem } from 'polished'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'

const CheckboxContainer = styled.div`
  margin-bottom: ${rem(24)};
`

interface Props extends Omit<ComponentProps<typeof CheckboxInput>, 'size'> {
  label?: string
}

const CheckboxField = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { label, children, className, ...checkboxProps } = props

    return (
      <CheckboxContainer className={className}>
        <CheckboxInput {...checkboxProps} size="large" ref={ref}>
          {props.label || children}
        </CheckboxInput>
      </CheckboxContainer>
    )
  },
)

CheckboxField.displayName = 'CheckboxField'

export default CheckboxField
