import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

export default function useEndPlaceIsSame(formMethods: UseFormReturn<any>) {
  const { getValues, setValue, watch } = formMethods

  useEffect(() => {
    const { unsubscribe } = watch((value, { name }) => {
      if (!name) {
        return
      }

      if (
        value.isEndPlaceSame &&
        (name.startsWith('startPlace') || name === 'isEndPlaceSame')
      ) {
        setValue('endPlace', { ...value.startPlace })
      }
    })

    return unsubscribe
  }, [getValues, setValue, watch])
}
